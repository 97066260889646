<template>
    <div>
       <b-row>
            <b-col :lg="boxSize.lg" :xl="boxSize.xl" :offset-xl="boxSize.offsetXl" :offset-lg="boxSize.offsetLg"> 
                <div class="mt-3 mb-2 d-flex align-items-center">
                    <b-icon icon="arrow-left" class="mr-2" v-if="data.form_step < 3"></b-icon>
                    <b-link v-if="data.form_step == 2" href="#" @click="goBack()" class="d-flex align-items-center">{{trans('previous-step',175)}}</b-link>
                    <b-link href="#" @click="toPlanning" v-if="data.form_step == 1">{{trans('to-job-offer',200)}}</b-link>
                </div>
                <h1 class="mt-2 mb-4" v-if="data.form_step == 1">{{trans('sh-create-bulk-service',219)}}</h1>
                <ValidationObserver ref="form">
                    <b-card class="mb-3" v-if="data.form_step == 1">               
                            <div class="app-card-profile-title mb-3">{{trans('planning-basic-information',209)}}</div>
                            <offers-shifts-add-bulk-select-department-component :data.sync="data"/>
                    </b-card>

                    <div class="mb-3" v-if="data.form_step == 1 && cDepartmentValidation == true">              
                       <offers-shifts-add-bulk-import-data-component :data.sync="data"/>
                    </div>

                    <div class="mb-3" v-if="data.form_step == 1 && cImportValidation == true && cDepartmentValidation == true">              
                       <offers-shifts-add-bulk-import-config-component :data.sync="data"/>
                    </div>

                    <div class="mb-3" v-if="data.form_step == 2">              
                       <offers-shifts-add-bulk-check-list-component :planData.sync="data"/>
                    </div>

                    <div class="mb-3" v-if="data.form_step == 3">
                        <offers-shifts-add-bulk-result-list-component :data.sync="data" :shifts.sync="added_shifts"/>
                    </div>
                    
                </ValidationObserver> 
                <div class="mt-4 mb-3" >
                    <app-button type="primary" :loading="loadingAddAndClose" v-if="data.form_step == 1" @click="goForward()">
                        {{trans('forward',175)}}
                    </app-button>
                    <app-button type="primary" :loading="loadingAddAndClose" v-if="data.form_step == 2" @click="add" :disabled="!cShiftsValidated">
                        {{trans('planning-create-service',210)}}
                    </app-button>
                    <app-button type="primary" @click="toPlanning()" v-if="data.form_step == 3">
                        {{trans('to-job-offer',200)}}
                    </app-button>

                    <app-button type="primary" @click="getCSV()" v-if="data.form_step == 3">
                        CSV genereren
                    </app-button>
                </div>
             </b-col>
       </b-row>
    </div>
</template>
<script>

import axios from "@axios";
import moment from "moment";
import OffersShiftsAddBulkSelectDepartmentComponent from './ComponentsBulk/Step1/OffersShiftsAddBulkSelectDepartmentComponent.vue';
import { ValidationObserver } from "vee-validate";
import OffersShiftsAddBulkImportDataComponent from './ComponentsBulk/Step1/OffersShiftsAddBulkImportDataComponent.vue';
import OffersShiftsAddBulkImportConfigComponent from './ComponentsBulk/Step1/OffersShiftsAddBulkImportConfigComponent.vue';
import OffersShiftsAddBulkCheckListComponent from './ComponentsBulk/OffersShiftsAddBulkCheckListComponent.vue';
import OffersShiftsAddBulkResultListComponent from './ComponentsBulk/OffersShiftsAddBulkResultListComponent.vue';

export default {
    components: {
        ValidationObserver,
        OffersShiftsAddBulkSelectDepartmentComponent,
        OffersShiftsAddBulkImportDataComponent,
        OffersShiftsAddBulkImportConfigComponent,
        OffersShiftsAddBulkCheckListComponent,
        OffersShiftsAddBulkResultListComponent
    },

    computed : {
        cDepartmentValidation : {
            get() { 
                    if (this.data.import.level == 0 && this.data.client != null) return true;
                    else if (this.data.import.level == 1 && this.data.client_location != null) return true;
                    else if (this.data.import.level == 2 && this.data.client_department != null) return true;
                    else return false;
            }
        },

        cImportValidation : {
            get() {
                if (this.data.import.items.length > 0) {
                    return true;
                } else {
                    return false;
                }
            }
        },

        cShiftsValidated : {
            get() { 
                if (this.data.shifts.items.length == 0 || (this.data.shifts.items.length > 0 && this.data.shifts.items.list.filter(element => element.list_type == 2).length > 0)) {
                    return false;
                } else {
                    return true;
                }
            }
        },

         cProcedure : {
            get() {
                if ((this.data.import.level == 2 || this.data.import.level == 1) && this.data.client_location != null) return this.data.client_location.import_procedure;
                else if (this.data.import.level == 0 && this.data.client != null) return this.data.client.import_procedure;
                else return null;
            }
        },
    },

    watch: {
        "data.shifts.items": {
            handler: function(val) {
                if(this.data.shifts.items.list){
                    for(var i=0;i<this.data.shifts.items.list.length;i++){
                        if(this.data.import.level < 2 && (!this.data.shifts.items.list[i].department || !this.data.shifts.items.list[i].function || !this.data.shifts.items.list[i].date_from || !moment(this.data.shifts.items.list[i].date_from, 'DD-MM-YYYY').isBetween(moment().subtract(8, 'days'), moment().add(1, 'years')) || !this.data.shifts.items.list[i].hour_from || !this.data.shifts.items.list[i].hour_to || !this.data.shifts.items.list[i].shift_type)){
                            this.data.import_error = true;
                        }else if(this.data.import.level == 2 && ( !this.data.shifts.items.list[i].function || !this.data.shifts.items.list[i].date_from || !moment(this.data.shifts.items.list[i].date_from, 'DD-MM-YYYY').isBetween(moment().subtract(8, 'days'), moment().add(1, 'years')) || !this.data.shifts.items.list[i].hour_from || !this.data.shifts.items.list[i].hour_to || !this.data.shifts.items.list[i].shift_type)){
                            this.data.import_error = true;
                        }else{
                            this.data.import_error = false;
                        }
                    }
                }
            },
            deep: true
        }
    },

    data() {
        return {
            boxSize:{
                lg:10,
                xl:8,
                offsetXl:2,
                offsetLg:1,
            },

            data : {
                form_step: 1,
                validation_configuration: false,
                selected_schema: null,

                office_location: null,
                client: null,
                client_location: null,
                client_department: null,

                import: {
                    items: [],
                    source: null,  // 1 file, 2 - clipboard
                    procedure: null, // 1 - ndividual algorithm, 2 - own column configuration 
                    level: 0, // 2 - department, 1 - location, 0 - client

                },

                columns: {
                    items: [],
                    headers: false,
                    refresh: 0
                },

                shifts: {

                    items: [],
                    filters: { 

                                sorting: {
                                            field_name: null,
                                            sort_direction: 0,
                                            changed: 0
                                        },
                                page: 1,
                                per_page: 10,
                                search: "",
                                list_type: 1,
                                changed: 0,
                            },
                    
                },   
                
                users_args: {
                    id_location_data_main: null, 
                    id_office_data_location:null
                },
                basic_informations: [],
                schemas: [],
                clients: [],    
                departments: [],
                locations: [],  
                import_error: false,
                last_schema:[],

            },
            loadingAddAndClose: false,
            loadingAdd: false,

            added_shifts: null,
        };
    },

    methods: {
        add() {
                this.loadingAddAndClose = true;
                var id_client_data_main = null;
                var tmp = [];

                if ((this.data.import.level == 2 || this.data.import.level == 1) && this.data.client_location != null) { // department and location
                    id_client_data_main = this.data.client_location.id_client_data_main;
                } else if (this.data.import.level == 0 && this.data.client != null) { // client
                    id_client_data_main = this.data.client.value;
                }

                for(var i=0;i<this.data.shifts.items.list.length;i++){
                    if(this.data.import.level < 2 && (!this.data.shifts.items.list[i].department || !this.data.shifts.items.list[i].function || !this.data.shifts.items.list[i].date_from || !moment(this.data.shifts.items.list[i].date_from, 'DD-MM-YYYY').isBetween(moment().subtract(8, 'days'), moment().add(1, 'years')) || !this.data.shifts.items.list[i].hour_from || !this.data.shifts.items.list[i].hour_to || !this.data.shifts.items.list[i].shift_type)){
                        this.loadingAddAndClose = false
                        return this.data.import_error = true;
                    }else if(this.data.import.level == 2 && ( !this.data.shifts.items.list[i].function || !this.data.shifts.items.list[i].date_from || !moment(this.data.shifts.items.list[i].date_from, 'DD-MM-YYYY').isBetween(moment().subtract(8, 'days'), moment().add(1, 'years')) || !this.data.shifts.items.list[i].hour_from || !this.data.shifts.items.list[i].hour_to || !this.data.shifts.items.list[i].shift_type)){
                        this.loadingAddAndClose = false
                        return this.data.import_error = true;
                    }else{
                        this.data.import_error = false;
                    }
                }

                this.data.shifts.items.list.forEach((item) => {
                    tmp.push({"id_d": item.department.id_department_data_main,
                              "id_c": item.department.id_cao_data_main,
                              "id_f": item.function.id_function_data_main,
                              "id_l": item.department.id_location_data_main,
                              "id_o": item.department.id_office_data_location,
                              "df": item.date_from,
                              "hf": item.hour_from,
                              "ht": item.hour_to,
                              "d": item.description,
                              "c_s": item.department.cao_source,
                              "id_sc": item.shift_type.value,
                              "f_a": item.function.ids_function_data_additional && item.function.ids_function_data_additional != '' ? item.function.ids_function_data_additional.toString().split(`,`).map(x=>+x) : [],
                              "id_p": item.id_person_data_main,
                              "p_n": item.person_name,
                              "f_n": item.function.name,
                              "d_n": item.department.name,
                              "sc":item.shift_type.name
                              });
                });
                axios
                        .post("planning/shifts/addBulk", {
                            shifts: JSON.stringify(tmp),
                            id_client_data_main: id_client_data_main
                            
                        })
                        .then((res) => {
                            
                            this.added_shifts = res.data;

                            this.boxSize.lg = 10;
                            this.boxSize.xl = 8;
                            this.boxSize.offsetXl = 2;
                            this.boxSize.offsetLg = 1;
                            this.data.form_step = 3;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loadingAddAndClose = false;
                        });

        },

        goForward() {
            this.$refs.form.validate().then((success) => {
                if (success && this.validateColumns() == true) {
                    if(this.data.form_step == 1 && this.data.selected_schema){
                        this.data.last_schema = {
                            'import_schema': JSON.stringify(this.data.columns.items),
                            'with_headers': this.data.columns.headers,
                            'name': this.data.selected_schema.name
                        }
                    }else if(this.data.form_step == 1){
                        this.data.last_schema = {
                            'import_schema': JSON.stringify(this.data.columns.items),
                            'with_headers': this.data.columns.headers,
                            'name': null
                        }
                    }
                    this.data.form_step = 2;
                    this.boxSize.lg = 12;
                    this.boxSize.xl = 12;
                    this.boxSize.offsetXl = 0;
                    this.boxSize.offsetLg = 0;
                }
            });
        },

        goBack(){
            this.data.form_step = 1;
            this.boxSize.lg = 10;
            this.boxSize.xl = 8;
            this.boxSize.offsetXl = 2;
            this.boxSize.offsetLg = 1;
        },

        toPlanning(){
                       
            this.$router.push({ name: "planning-offers"});
        },

        getCSV(){

            Object.values(this.added_shifts.register_people).forEach(person => {
                person.shifts.forEach(shift => {
                    var temp_shift = this.added_shifts.shifts.find(x=> x.id_sh == shift.id_sh);
                    temp_shift.person = person.person_name;
                    temp_shift.registration = shift.status == 1 ? this.trans('yes-option',3) : this.trans('no-option',3);
                })
                
            });

            var headers= [];

            if(this.data.import.level == 2 && this.data.shifts.items.list[0].department != null){
                headers.push(this.trans('department',182));
            }else if(this.added_shifts.shifts.filter(e => e.d_n != null).length > 0){
                headers.push(this.trans('department',182));
            }
            if(this.added_shifts.shifts.filter(e => e.f_n != null).length > 0){
                headers.push("Functie");
            }
            if(this.added_shifts.shifts.filter(e => e.df != null).length > 0){
                headers.push(this.trans('date',182));
            }
            if(this.added_shifts.shifts.filter(e => e.hf != null).length > 0){
                headers.push(this.trans('planning-begin',209));
            }
            if(this.added_shifts.shifts.filter(e => e.ht != null).length > 0){
                headers.push(this.trans('planning-end',209));
            }
            if(this.added_shifts.shifts.filter(e => e.sc != null).length > 0){
                headers.push(this.trans('planning-service-type',209));
            }
            if(this.added_shifts.shifts.filter(e => e.fa != null).length > 0){
                headers.push(this.trans('deviant-funtions',182));
            }

            if(this.added_shifts.shifts.filter(e => e.person != null).length > 0){
                headers.push(this.trans('flexworker',182));
            }  

            headers.push("Geregistreerd");

            if(this.added_shifts.shifts.filter(e => e.d != null).length > 0){
                headers.push(this.trans('td-description',205));
            }

            var res =this.added_shifts.shifts.map((o) =>{
                var res = [];
                if(this.data.import.level == 2 && headers.includes(this.trans('department',182))){
                    res.push(this.data.shifts.items.list[0].department.name);
                }else if(headers.includes(this.trans('department',182))){
                    res.push(o.d_n);
                }
                if(headers.includes("Functie")){
                    res.push(o.f_n);
                }
                if(headers.includes(this.trans('date',182))){
                    res.push(o.df);
                }
                if(headers.includes(this.trans('planning-begin',209))){
                    res.push(o.hf);
                }
                if(headers.includes(this.trans('planning-end',209))){
                    res.push(o.ht);
                }
                if(headers.includes(this.trans('planning-service-type',209))){
                    res.push(o.sc);
                }
                if(headers.includes(this.trans('deviant-funtions',182))){
                    res.push(o.fa);
                }
               
                if(headers.includes(this.trans('flexworker',182))){
                    res.push(o.person);
                }

                if(headers.includes("Geregistreerd")){
                    res.push(o.registration ? o.registration : '');
                }

                if(headers.includes(this.trans('td-description',205))){
                    res.push(o.d);
                }
                return res;
                
            });

            res.unshift(headers)

            let csvContent = "data:text/csv;charset=utf-8," + res.map(e => e.join(";")).join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "diensten_lijst.csv");
            document.body.appendChild(link); // Required for FF

            link.click(); 
        },

        validateColumns(){

            var result = true;

            if (this.cProcedure == '1') {
                this.data.validation_configuration = false;
                return result;
            }

            var types = [];
            this.data.columns.items.forEach(element => {
                if(element.type){
                    types.push(element.type.value);
                }
            });
            
            if(types.length == 0) {
                this.data.validation_configuration = true;
                return 'nofile';
            }
            
            if(this.data.client_department == null && !types.includes(1)) {
                this.data.validation_configuration = true;
                return false;
            }

            if(!types.includes(2)){ 
                this.data.validation_configuration = true;
                return false; // function
            }

            if ((types.includes(4) && types.includes(6) && types.includes(7)) ||  // date / hour from / hour to
                (types.includes(4) && types.includes(8)) || // date / hour from to
                (types.includes(5) && types.includes(7)) ||
                (types.includes(5) && types.includes(9))) { 
                    this.data.validation_configuration = false;
                    return true;   // date and hour from / hour to
            }else { 
                this.data.validation_configuration = true;
                return false; 
            }

        }
    },
 
};
</script>