<template>
    <b-modal ref="modal-change" size="sm" centered hide-header v-model="computedShow">

        <p class="text-center text-primary">
            <b-icon icon="question-circle"  font-scale="6"></b-icon>
        </p>
        <p class="text-center text-base" v-if="args.type == 1 && args.sameDepartmentItems.length > 0" v-html="this.trans('sh-found-same-departments-confirmation',221, {length : args.sameDepartmentItems.length})"></p>
        <p class="text-center text-base" v-if="args.type == 1 && args.sameDepartmentItems.length == 0">{{this.trans('sh-department-replace-confirmation',221)}}</p>

        <p class="text-center text-base" v-if="args.type == 2 && args.sameFunctionItems.length > 0" v-html="this.trans('sh-found-same-functions-confirmation',221, {length : args.sameFunctionItems.length})"></p>
        <p class="text-center text-base" v-if="args.type == 2 && args.sameFunctionItems.length == 0">{{this.trans('sh-function-replace-confirmation',221)}}</p>

        <div slot="modal-footer" class="app-local-justify" style="justify-content: center !important;">
             <div style="display:flex">
                <b-button variant="primary" size="sm" @click="setOption(1)" :disabled="loading" class="app-button mr-2">{{trans('yes-option',3)}}</b-button>
                <b-button variant="cancel" v-if="(args.type == 1 && args.sameDepartmentItems.length > 0) || (args.type == 2 && args.sameFunctionItems.length > 0)" size="sm" @click="setOption(2)" :disabled="loading" class="btn btn-light btn-secondary-grey btn-cancel-modal">{{trans('no-option',3)}}</b-button>
                <b-button variant="cancel" size="sm" @click="setOption(3)" :disabled="loading" class="app-button app-button-local">{{trans('cancel',175)}}</b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>

export default {

    props: ["show", "args", "result", "return"],

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
    },

    data(){
        return{
            loading: false,
            loadingCancelled: false,
            loadingLost: false,
            loadingCorrection: false,
        }
    },

    methods: {

        setOption(option){
            this.cArgs.option = option;
            if(this.cArgs.type == 1){
                this.$emit("update:result", 1);
            }else{
                this.$emit("update:result", 2);
            }
            this.computedShow = false;
        },

        closeModal() {
            this.computedShow = false;
        },
    }
    
}
</script>
<style scoped>
.app-local-justify{
    justify-content: center !important;
    margin: auto;
}

.app-button-local{
    order: 3
}
</style>