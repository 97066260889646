<template>
    <div>
        <b-overlay :show="loading" :opacity="0.6">
            
            <b-card>               

                <b-alert variant="light" show class="mb-0">
                    
                        <b-row  align-v="center">
                            <b-col md="8">
                                
                                <div class="d-flex align-items-center">
                                    <div>
                                        <b-icon icon="check-circle-fill" font-scale="3" class="mr-4"></b-icon>                        
                                    </div>

                                    <div class="w-100 align-items-center">           
                                        <span class="align-middle font-weight-600 text-base" v-bind:class="{ 'app-local-success': shifts.total_added_shifts == shifts.total_shifts, 'app-local-warning': shifts.total_added_shifts != shifts.total_shifts}">{{trans('sh-added-services-to-total-shifts',215, {total_added_shifts : shifts.total_added_shifts, total_shifts : shifts.total_shifts})}}</span><br/>
                                        <b-row>
                                            <b-col end sm="12" lg="auto">    
                                                <span class="app-local-person-data" v-if="data.import.level == 0"> {{data.office_location.name}} | {{data.client.name}}</span>
                                                <span class="app-local-person-data" v-if="data.import.level == 1"> {{data.office_location.name}} | {{data.client_location.name}}</span>
                                                <span class="app-local-person-data" v-if="data.import.level == 2"> {{data.office_location.name}} | {{data.client_location.name}} | {{data.client_department.name}} </span>
                                            </b-col>
                                        </b-row>
                                    </div>

                                </div>
                            </b-col>
                        </b-row>

                </b-alert>

            </b-card> 

            <app-collapse accordion type="margin" class="mt-3" v-if="shifts.register_people">
                <b-card no-body class="app-card-profile" v-for="item in shifts.register_people" :key="item.id_person_data_main">
                    <app-collapse-item style="box-shadow:none !important;" :id="'person_'+item.person_name">
                        <template v-slot:header>
                            <span class="lead collapse-title">{{item.person_name}}</span>
                            <span class="text-muted ml-1"> <span class="app-local-person-data" v-bind:class="{ 'app-local-success': getAddedShiftByPerson(item.shifts) == item.shifts.length, 'app-local-warning': getAddedShiftByPerson(item.shifts) != item.shifts.length}"> (Toegevoegd aan {{getAddedShiftByPerson(item.shifts)}} van {{item.shifts.length}}) </span> </span>
                        </template>
                            
                        <b-row class="ml-1 mr-1 pb-2 mt-3 app-local-row-header-font">
                            <!--<b-col sm="1"  md="1" class="pr-0 app-local-checkbox-all">
                                <app-check-box v-model="selectedAll" value="1" unchecked-value="0" v-on:change="selectAll" />
                            </b-col>-->
                            <b-col sm="11" md="11">
                                <b-row>
                                    <b-col cols="2">
                                       {{trans('date',182)}}
                                    </b-col>
                                    <b-col cols="2">
                                        {{trans('sh-hour',215)}}
                                    </b-col>
                                    <b-col cols="2">
                                        {{trans('planning-service-type',209)}}
                                    </b-col>
                                    <b-col cols="3">
                                        {{trans('flexworker',182)}}
                                    </b-col>
                                    <b-col cols="3">
                                        {{trans('detachment',182)}}
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="1"  md="1" class="pr-0">
                            </b-col>
                        </b-row>

                        <b-list-group flush class="app-local-group-list mb-2" >

                            <b-list-group-item  v-for="shift in item.shifts" :key="shift.id_shift_data_main" class="app-local-group-item">
                                        
                                <b-row align-v="center">
                                    <!--<b-col sm="1" md="1" class="pr-0">
                                        <app-check-box v-model="shift.selected" value="1" unchecked-value="0" v-on:change="selectedChanged(shift)"/>
                                    </b-col>-->

                                    <b-col sm="11" md="11">

                                        <b-row align-v="center">
                                            <b-col cols="2">
                                                {{shift.df}}
                                            </b-col>
                                            <b-col cols="2">
                                                {{shift.hf}} - {{shift.ht}}
                                            </b-col>
                                            <b-col cols="2">
                                                {{getDayTime(shift.id_sc)}}
                                            </b-col>
                                            <b-col cols="3" v-if="shift.id_p != null">
                                                    <div class="app-local-success" v-if="shift.status >= 0">{{trans('planning-added',173)}}</div>
                                                    <div class="app-local-warning" v-if="shift.status < 0">{{trans('sh-none-added',215)}}</div>
                                                    <div class="app-local-warning" v-if="shift.status < 0">{{trans(shift.error, 171)}}</div>
                                            </b-col>
                                            <b-col cols="3" v-if="shift.shift != null && shift.shift.assignment != null">
                                                {{shift.shift.assignment.details}}
                                            </b-col>
                                        </b-row>
                                    </b-col>

                                    <b-col sm="1" md="1" class="text-right">
                                        
                                        <b-dropdown variant="link"  no-caret right>
                                                <template #button-content>
                                                    <b-icon icon="three-dots-vertical" class="text-body" ></b-icon>
                                                </template>
                                                
                                                <b-dropdown-item @click="openShift(shift)">{{trans('open',175)}}</b-dropdown-item>
                                                <!--<b-dropdown-item @click="cancelItem(shift)">Dienst annuleren</b-dropdown-item>-->

                                            </b-dropdown> 
                                    </b-col>

                                </b-row>

                            </b-list-group-item>

                        </b-list-group>

                        <!--<b-dropdown no-caret toggle-class="app-button-multi" variant="secondary-grey" v-if="selectedIds.length > 0">
                            <template #button-content>
                                    Actie ({{selectedIds.length}})                         
                            </template>
                                
                            <b-dropdown-item @click="cancelSelectedItems()">Diensten annuleren</b-dropdown-item>                             
                            
                        </b-dropdown>-->

                    </app-collapse-item>
                </b-card>
            </app-collapse>

            <template #overlay>
                <div></div>
            </template>
        </b-overlay>    
        
    </div>
</template>
<script>
import axios from "@axios";
import moment from 'moment';
import AppAlerts from '@core/scripts/AppAlerts';
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";

export default {
    components: {
        AppCollapse,
        AppCollapseItem  
    },

    props:["data", "shifts"],

    watch: {
        
    },

    data() {
        return {
            loading: false,
            selectedAll: "0",
            selectedIds: [],
            alertClass: new AppAlerts(),
            function_name: ""
        };
    },

    methods: {
        getDayTime(idShiftConstDaytime){

            if(idShiftConstDaytime == 1){
                return this.trans('day-part-morning',182);
            }else if(idShiftConstDaytime == 2){
                return this.trans('day-part-afternoon',182);
            }else if(idShiftConstDaytime == 3){
                return this.trans('day-part-day',182);
            }else if(idShiftConstDaytime == 4){
                return this.trans('day-part-evening',182);
            }else if(idShiftConstDaytime == 5){
                return this.trans('day-part-night',182);
            }else if(idShiftConstDaytime == 6){
                return this.trans('sleeping-service',182);
            }

        },

        setFormatDate(dateValue) {
           return moment(dateValue).format('DD-MM-YYYY');
        },

        getAddedShiftByPerson(shifts){
            
            if(shifts != null){
                let counter = 0;

                shifts.forEach(item => {
                    if(item.status >= 0){
                        counter++;
                    }
                });
                
                return counter;
            }

            return 0;
        },

        /*selectAll(){
            if(this.selectedAll == 0){
                this.shifts.forEach(element => {
                    element.selected = "0";
                    this.selectedIds.splice(this.selectedIds.indexOf(element.id), 1);
                });

            } else {
                this.selectedIds = [];
                this.shifts.forEach(element => {
                    element.selected = "1";
                    this.selectedIds.push(element.id);
                });
            }
        },*/

        /*selectedChanged(item){
            if(item.selected == "1"){
                this.selectedIds.push(item.id);
            }
            else if(item.selected == "0") {
                this.selectedIds.splice(this.selectedIds.indexOf(item.id), 1);
            }

            if(this.selectedAll == 1){
                this.shifts.forEach(element => {
                    if(element.selected == "0"){
                        this.selectedAll = 0;
                    }
                });
            }

            if(this.selectedAll == 0){
                var counter = 0;

                this.shifts.forEach(element => {
                    if(element.selected == "1"){
                        counter++;
                    }
                });

                if(counter == this.shifts.length){
                    this.selectedAll = 1;
                }
            }
        },*/

        openShift(item){
            let routeData = this.$router.resolve({name: 'planning-shifts-profile', params: { id_shift_data_main: item.id_sh }});
            window.open(routeData.href, '_blank');
        },

        /*cancelItem(item){
            this.cancelShifts([item.id]);
        },*/

        /*cancelSelectedItems(){
            this.cancelShifts(this.selectedIds);
        },*/

        /*cancelShifts(idsShiftDataMain){

             this.alertClass.openAlertConfirmation("Weet je zeker dat je wilt annuleren?").then(res => {

                if(res == true){

                    this.loading = true;
                    axios
                        .post("planning/shifts/cancel", {
                            ids_shift_data_main: JSON.stringify(idsShiftDataMain),
                            id_shift_const_status: 17
                        })
                        .then((res) => {
                            //
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {

                            var tmpArray = this.shifts;

                            idsShiftDataMain.forEach((item) => {

                                for(var i=0; i<tmpArray.length; i++){
                                    if(item == tmpArray[i].id){
                                        tmpArray.splice(i, 1);
                                        break;
                                    }
                                }

                            });

                            this.cShifts = tmpArray;
                            this.selectedIds = [];
                            this.selectedAll = 0;

                            this.loading = false;
                        });

                }

             });

        }*/
    }

};
</script>

<style scoped>

.app-local-person-data{
    font-size: 14px;
}

.custom-avatar{
    height: 52px;
    width: 52px;
}

.app-local-group-list{
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-group-item{
    padding: 0.5rem 1rem;
    color: #174066;
    font-weight: 300;
    font-size: 14px;
}

.app-local-group-list:hover{
    background-color: #f6f9fc;
    z-index: auto;
    text-decoration: none;
    cursor: pointer;
}

.app-local-row-header-font {
    font-weight: 600;
    color: #1f627f;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-checkbox-all{
    padding-left: 12px;
}

.app-local-warning{
    color: #d92d20;
}

.app-local-success{
    color: #12B76A;
}

</style>
