<template>
    <b-modal ref="modal-edit" v-model="computedShow" no-close-on-backdrop :title="trans('cp-change-departament-aliases',192)">
        <b-overlay :show="loading_aliases == true" :opacity="0.0">
            <ValidationObserver ref="form" >
                <b-form @submit.prevent="onSubmit">

                    <app-row-left-label :label="trans('aliases',182)">
                        <app-tags v-model="name_alias" :key="refresh" :newTag="new_alias" v-if="loading_aliases == false"/>
                    </app-row-left-label>

                </b-form>
            </ValidationObserver>
            <template #overlay>
               <div>
                    <b-spinner  small class="app-spinner-size app-local-spinner"></b-spinner>                         
               </div>
            </template>
        </b-overlay>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="changeDepartmentAlias" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppTags from '@core/components/AppTags.vue';

export default {
    components: {
        ValidationObserver,
        AppTags
    },

    props: ["show", "result","args", "return"],

    mounted(){
        this.getTags();
        
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading_aliases: false,
            loading: false,
            tag_name:null,
            name_alias:[],
            new_alias: '',
            refresh: 0,
        };
    },

    methods: {
        tagValidator(tag) {
            if(tag.length > 60){
                return false;
            }else{
                return true;
            }
        },

        getTags(){
            this.loading_aliases= true;
            axios
                .post("clients/profile/location/department/getDepartmentAlias", {
                    id_department_data_main: this.cArgs.id_department_data_main
                })
                .then((res) => {
                    if(res.data.name_alias != null){
                        this.name_alias = res.data.name_alias.split(',');
                    }
                    if('alias' in this.args && this.alias != ''){
                        this.new_alias = this.args.alias;
                    }
                    this.refresh++;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_aliases = false;
                });
        },
        
        changeDepartmentAlias() {

            this.$refs.form.validate().then((result) => {
                if (!result && this.tag_name.length > 60) {
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("clients/profile/location/department/changeDepartmentAlias", {
                             id_department_data_main: this.cArgs.id_department_data_main,
                             name_alias: JSON.stringify(this.name_alias)
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            if(res.data.items != null){
                                res.data.items.name_alias = res.data.items.list[i].name_alias.split(',');
                            }
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading = false;
                        });
                    }
            })
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped>
.app-local-spinner{
    margin-bottom: 6px !important; 
    border-width: 0.14em !important;
    width: 1.2em !important;
    height: 1.2em !important; 
}
</style>
