<template>
    <div>
        <b-row>
            <b-col md="6" xl="6">
                <app-select v-model="cData.office_location" :label="trans('planning-hn-branch',209)" type="getUserLocations" :disable="false" validatorName="HN Vestiging" validatorRules="required" :refreshIndex.sync="refresh_user_locations" @loaded="setOffice"/>
            </b-col>

            <b-col md="6" xl="6">
                <app-radio-group :label="trans('sh-input-lvl',219)" v-model="cData.import.level" :options="options"/>
            </b-col>
            
        </b-row>
        <b-row class="mt-2">
            <b-col md="6" xl="6" v-if="cData.import.level == 0">
                <app-select v-model="cData.client" :label="trans('organization',169)" :options="cData.clients" :disable="cData.office_location == null" :customLoading="loading_clients" validatorName="Organisatie" validatorRules="required" :key="refresh_clients"/>
            </b-col>
            <b-col md="6" xl="6" v-if="cData.import.level > 0">
                <app-select v-model="cData.client_location" :label="trans('location',3)" :options="cData.locations" :disable="cData.office_location == null" :customLoading="loading_clients" validatorName="Locatie" validatorRules="required" :key="refresh_client_locations"/>
            </b-col>
            <b-col md="6" xl="6"  class="d-flex w-100" v-if="data.import.level == 2">    
                <app-select class="w-100" v-model="cData.client_department" :label="trans('department',182)" :options="cData.departments" :disable="cData.client_location == null" :customLoading="loading_departments" validatorName="Afdeling" validatorRules="required" :key="refresh_client_departments"/>        
            </b-col>
        </b-row>

    </div>
</template>
<script>

import axios from "@axios";

export default {

    props: {
        data: {}
    },

    mounted() {
        this.refresh_user_locations++;
    },

    computed: {
        cData: {
            get() { return this.data },
            set(value) { this.$emit('update:data', value) }
        },

    },

    data() {
        
        return {     
            refresh_user_locations: 1,
            refresh_client_departments: 1,
            refresh_client_locations: 1,
            refresh_clients: 1,

            loading_clients: false,
            loading_departments: false,

            office_location_args : {id_office_data_location: null},
            client_location_args: {id_location_data_main: null},

            options: [
                { text: this.trans('organization',169), value: 0 },
                { text: this.trans('location',3) , value: 1 },
                { text: this.trans('department',182), value: 2 }
            ]
        };
    },

    watch: {
        
        "data.office_location": {
            handler: function(val) {
                this.cData.client_department = null;
                this.cData.client_location = null;
                this.cData.client = null;

                this.client_location_args.id_location_data_main = null;

                this.getOfficeLocationForDepartmentSelection();
                this.refresh_clients++;
                this.refresh_client_locations++;
                this.cData.validation_configuration = false;

                this.cData.import.items = [];
               
                if(this.cData.office_location != null) {
                    this.office_location_args.id_office_data_location = this.cData.office_location.value;
                } else { 
                    this.office_location_args.id_office_data_location = null;
                };                
            }
        },        

        "data.client_location": {
            handler: function(val) {
                if(val != null){
                    this.loading_departments = true;
                    this.cData.departments = this.cData.basic_informations
                                        .filter(x => x.id_location_data_main ==  val.value )
                                        .map((x)=>{
                                            var a = {};
                                            a.name = x.department_name;
                                            a.value = x.id_department_data_main;
                                            a.id_cao_data_main = x.id_cao_data_main;
                                            a.cao_source = x.cao_source;
                                            a.id_location_data_main = x.id_location_data_main;
                                            a.id_department_data_main = x.id_department_data_main;
                                            a.name_alias = x.name_alias;
                                            a.id_office_data_location = x.id_office_data_location;
                                            return a;
                                        });
                    
                    this.cData.departments = [...new Map(this.cData.departments.map(item =>
                                        [item['value'], item])).values()].filter(x => x.value != null);
                    this.cData.client_department = null;
                    
                    if(this.cData.departments.length == 1 && this.cData.import.level == 2){
                        this.cData.client_department = this.cData.departments[0];
                    }

                    this.refresh_client_departments++;
                    //zaladowanie schematu
                    this.loadSchema(this.cData.client_location.id_client_data_main)
                }else{
                    this.cData.departments = [];
                }
                this.loading_departments = false;
            
                

                this.cData.department = null;
                if(this.cData.client_location != null) {
                    this.client_location_args.id_location_data_main = this.cData.client_location.value;
                } else { 
                    this.client_location_args.id_location_data_main = null;
                };
                
                if (val !== null) {this.refresh_client_departments++;}

            }         
        },

        "data.client": {
            handler: function(val) {
                if(val != null){
                    this.loadSchema(this.cData.client.id_client_data_main)
                }
            }
        },
        
        "data.import.level": {
            handler: function(val) {                 
                if(this.cData.office_location != null){
                    this.filterArray();
                }

                this.cData.validation_configuration = false;

                this.cData.client = null;
                this.cData.client_location = null;
                this.cData.client_department = null;
                this.cData.import.items = [];
                this.cData.columns.items = [];
                
                if (this.cData.office_location !== null) {
                    
                    this.office_location_args.id_office_data_location = this.cData.office_location.value;

                    setTimeout(() => {
                        
                        if (val == 0) {
                            this.refresh_clients++;
                        }else if (val == 1) {
                            this.refresh_client_locations++;
                        }else if (val == 2) {
                            
                            if(this.client_location_args.id_location_data_main != null){
                                this.refresh_client_departments++;
                            }
                        }

                    }, 10);

                }
                
            },
            deep: true
        }
    },

    methods: {
        getOfficeLocationForDepartmentSelection(){
            this.loading_clients = true;

            axios
                .post("planning/shifts/getOfficeLocationForDepartmentSelection", {
                    id_office_data_location: this.cData.office_location.value, 
                })
                .then((res) => {
                    this.cData.basic_informations = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.filterArray();
                });

        },

        filterArray(){
                
            this.cData.departments = [];
            this.cData.clients = [];
            this.cData.locations = [];
            const unique_clients = [...new Map(this.cData.basic_informations.map(item =>
                                [item['id_client_data_main'], item])).values()];
            
            for(var i=0;i<unique_clients.length;i++){
                this.cData.clients.push({'name':unique_clients[i].client_name, 'id_client_data_main':unique_clients[i].id_client_data_main,'value':unique_clients[i].id_client_data_main, 'import_procedure': unique_clients[i].import_procedure})
            }

            if(this.cData.clients.length == 1){
                this.cData.client = this.cData.clients[0];
                this.refresh_clients++;
            }

            const unique_locations = [...new Map(this.cData.basic_informations.map(item =>
                                [item['id_location_data_main'], item])).values()];
            
            for(var i=0;i<unique_locations.length;i++){
                this.cData.locations.push({'name':unique_locations[i].clients_location_name, 'value':unique_locations[i].id_location_data_main, 'id_client_data_main':unique_locations[i].id_client_data_main, 'import_procedure':unique_locations[i].import_procedure, 'registration_process':unique_locations[i].registration_process, 'registration_tool':unique_locations[i].registration_tool, 'client_confirmation':unique_locations[i].client_confirmation, 'send_notification':unique_locations[i].send_notification,})
            }

            this.cData.locations.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0))

            if(this.cData.locations.length == 1){
                this.cData.client_location = this.cData.locations[0];
                this.refresh_client_locations++;
            }
            this.loading_clients = false;
        },

        loadSchema(id_client_data_main){
            this.cData.schemas = this.cData.basic_informations
                                    .filter(x => x.id_client_data_main == id_client_data_main )
                                    .map((x)=>{
                                        var a = {};
                                        a.name = x.schema_name;
                                        a.value = x.schema_value;
                                        a.import_schema = x.import_schema;
                                        a.with_headers = x.with_headers;
                                        return a;
                                    });
            this.cData.schemas = [...new Map(this.cData.schemas.map(item =>
                                    [item['value'], item])).values()].filter(x => x.value != null);
            this.cData.schemas.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0))
        },

        setOffice(event){
            if(this.cData.client == null){
                var user_locations = JSON.parse(localStorage.getItem("USER_LOCATIONS"));
                
                if(user_locations.length == 1){
                    if( event.filter(i => i.value == user_locations[0].value).length == 1 ){

                        this.cData.office_location = user_locations[0];
                    }
                }
            }
        }

    },
   
};
</script>
