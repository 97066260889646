<template>
    <div>
        <!-- <b-input-group class="input-group-merge" >
            <b-form-input  v-model="cFunctionAddictional" size="sm" :lazy="true" class="app-input app-ellips custom-search" @click="searchFunctions()" :disabled="disabled" readonly/>

            <b-input-group-append is-text v-if="!disabled">
                <button  v-if="functions_name != null && functions_name.length > 0" type="button" title="Clear Selected" aria-label="Clear Selected" class="vs__clear" style="" @click="clear()"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg></button>
                <b-icon icon="search" class="custom-search font-weight-800" font-scale="1.1" @click="searchFunctions()"></b-icon> 
            </b-input-group-append>
        </b-input-group> -->
        <app-input v-model="cFunctionAddictional" @click="searchFunctions()" :disabled="disabled" readonly/>
        <offers-shifts-add-bulk-additional-functions-change v-if="mAdditionalFunctionsChange.show" :show.sync="mAdditionalFunctionsChange.show" :result.sync="mAdditionalFunctionsChange.result" :args.sync="mAdditionalFunctionsChange.args"/>
    </div>
</template>
<script>


import axios from "@axios";
import OffersShiftsAddBulkAdditionalFunctionsChange from './OffersShiftsAddBulkAdditionalFunctionsChange.vue'

export default {

    components: {
        OffersShiftsAddBulkAdditionalFunctionsChange,
    },

    props: {
            department: {},
            function: {},
            disabled: { type: Boolean, default: false },            
    },

    computed: {
        cFunction: {
            get() { return this.function },
            set(value) { this.$emit('update:function', value) }
        },

        cFunctionAddictional: {
            get() { 
                    if (this.cFunction != null) {
                        return this.cFunction.additional_names;
                    } else {
                        return null;
                    } 
                },            
        }
    },

    watch: {
        "mAdditionalFunctionsChange.result": {
             handler: function(val) {
                if(this.mAdditionalFunctionsChange.result == 1){
                    this.cFunction.additional_names = this.mAdditionalFunctionsChange.args.additional_names;
                    this.cFunction.ids_function_data_additional = this.mAdditionalFunctionsChange.args.ids_function_data_additional; 
                    this.mAdditionalFunctionsChange.result = 0;     
                }
            },
            deep:true
        },
    },

    data() {
        return {
            mAdditionalFunctionsChange: { show: false, args: {
                id_cao_data_main: null,
                ids_function_data_additional:null,
                id_function_data_main: null,
                additional_names:null,
            }, result: 0, return: null },
            functions_name: null,
        };
    },

    methods: {
        searchFunctions() {
            if (this.function != null) {
                this.mAdditionalFunctionsChange.args.id_cao_data_main = this.department.id_cao_data_main;
                this.mAdditionalFunctionsChange.args.id_function_data_main = this.function.id_function_data_main;
                this.mAdditionalFunctionsChange.args.ids_function_data_additional = this.function.ids_function_data_additional;
                this.mAdditionalFunctionsChange.args.additional_names = this.function.additional_names;
                this.mAdditionalFunctionsChange.show = true;
            }
            
        },

        clear(){
            this.cFunction.additional_names = '';
            this.cFunction.ids_function_data_additional = '';
        }
        
    }

    };
</script>

<style scoped>
.input-group-text {
    padding: 0px 10px !important
}
.custom-search{
    cursor: pointer;
}

.custom-search:hover{
    color: rgba(0,0,0,.8) !important;
}

</style>