<template>
    <div>
        <b-card class="mb-3">               
        <div class="app-card-profile-title mb-3">{{trans('sh-import-services',215)}}</div>
            <b-alert v-if="cData.import.items.length == 0" variant="light" show class="mb-0">
                <input class="file-upload-input app-local-cursor-auto" type="file" ref="imageUploader" accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" @click.prevent v-on:change="addFile($event)"/>
                <div class="d-flex w-100 justify-content-between" >
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon variant="secondary" icon="cloud-download-fill" font-scale="3" class="mr-4"></b-icon>                        
                        </div>
                        
                        <div class="w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('sh-select-data-source',215)}} </span><br/>  
                            <span class="text-small">{{trans('sh-drag-or-select-csv-xlsx',215)}}</span>
                            <div v-if="cImportProcedure == '1'" class="mt-3 text-small" v-html="trans('sh-individual-import-procedure-info',221)"></div>                                                                     
                        </div>
                    </div>
                    <div class="d-flex align-items-center ml-2" style="min-width:160px">
                        <app-button type="secondary-grey" :loading="file_uploading">{{trans('sh-select-document',221)}}
                            <input class="file-upload-input" type="file" ref="imageUploader" accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" v-on:change="addFile($event)"/>
                        </app-button>
                        <app-button v-if="cImportProcedure != '1'" @click="pasteText" type="secondary-grey" :disabled="file_uploading" >{{trans('sh-paste-from-clipboard',221)}}</app-button>                        
                    </div>
                </div>                
            </b-alert> 

            <b-alert v-else variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon variant="secondary" icon="cloud-download-fill" font-scale="3" class="mr-4"></b-icon>                        
                        </div>

                        <div class="w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{this.cData.import.items.length}} {{trans('sh-n-rows-found',221)}} </span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center ml-2" style="min-width:160px">
                        <app-button @click="removeImportData" type="secondary-grey">{{trans('sh-delete-services',220)}}</app-button>                        
                    </div>
                </div>
            </b-alert>

             
            </b-card>
            <div id="pasteContainer" style="display:none; visibility: hidden; height: 5px"></div>        
    </div>
</template>
<script>

import axios from "@axios";
import * as CSV from 'csv-string';
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
    },

    props: {
        data: {},
    },

    computed: {
        cData: {
            get() { return this.data; },
            set(value) { this.$emit('update:data', value); }
        },

        cImportProcedure: {
            get() {
                    if (this.cData.client !== null && this.data.import.level == 0) return this.cData.client.import_procedure;
                    else if (this.client_location !== null && (this.data.import.level == 1 || this.data.import.level == 2)) return this.cData.client_location.import_procedure;
                    else return null;
                },
        }
    },

    watch: {
        "data.client":{
            handler: function(){
                this.removeImportData();
            },
            deep: true
        },

        "data.client_location":{
            handler: function(){
                this.removeImportData();
            },
            deep: true
        },
        "data.client_department":{
            handler: function(){
                this.removeImportData();
            },
            deep: true
        }
    },

    data() {
        return {      
            file_uploading: false,
            alertClass: new AppAlerts()
        };
    },

    methods: {

        dropLink() {
        },

        async pasteText() {
            
            let userAgent = navigator.userAgent;
            let parse_result = null;
            let clipboard_contents = null;
            
            if(userAgent.match(/firefox|fxios/i)){
                clipboard_contents = await navigator.clipboard.readText();
                parse_result = clipboard_contents;
            }else{
                clipboard_contents = await navigator.clipboard.read();

                for (let item of clipboard_contents) {
                    if (item.types.includes('text/html')) {

                        let blob = await item.getType('text/html');
                        parse_result = this.parseHtml(await blob.text());
                        
                    } else if (item.types.includes('text/plain')) {

                        let blob = await item.getType('text/plain');
                        parse_result = await blob.text();

                    }            
                }
            }

            let rows = null;
            if (parse_result != null && parse_result != "") {
                rows = CSV.parse(parse_result);
            } else {
                this.alertClass.openAlertWarning(this.trans('sh-shifts-not-found',221)).then(res => {});
                return null;
            }

            if (Array.isArray(rows) && rows.length > 0 && Array.isArray(rows[0])) {
                this.cData.import.items = rows;                
            } else {
                this.cData.import.items = [];
               this.alertClass.openAlertWarning(this.trans('sh-shifts-not-found',221)).then(res => {});
                return null;
            }
        
        },

        parseHtml(selected_text) {

            var html = document.getElementById('pasteContainer');
            html.innerHTML = selected_text;
            
            var parse_result = "";


            let html_tables = html.getElementsByTagName('table');
            if (html_tables != null && html_tables.length > 0) {  
                let re = new RegExp("<\/?\\w+((\\s+\\w+(\\s*=\\s*(?:\".*?\"|'.*?'|[^'\">\\s]+))?)+\\s*|\\s*)\/?>", 'igm');
                let html_rows = html_tables[0].getElementsByTagName('tr');
            
                for (let k = 0; k < html_rows.length; k++) {
                    
                    let html_cells = null;
                    if ('querySelectorAll' in document) {
                        html_cells = html_rows[k].querySelectorAll('td,th');
                    } else {
                        html_cells = html_rows[k].getElementsByTagName('td');
                        if (!html_cells || html_cells.length == 0) {
                            html_cells = html_rows[k].getElementsByTagName('th');
                        }
                    }

                    for (var n = 0; n < html_cells.length; n++) {
                        let value = html_cells[n].innerHTML;
                        if (value == null) value = ""; else value += "";
                        
                        value = value.replace(/\r\n|\r|\n/gmi, ' ');
                        value = value.replace('<o:p>', '');
                        value = value.replace('</o:p>', '');
                        
                        value = value.replace(/\n|<br>|<br\/>|<br \/>/gmi, '\n');
                        value = value.replace(re, '');
                        
                        value = unescape(value);
                        value = value.replace(/&nbsp;/gmi, " ");
                        value = value.trim();
                        
                        parse_result += value.replace(';', '"') + ';';
                    }
                    parse_result = parse_result.slice(0,  parse_result.length - 1); // remove last delimiter
                    parse_result += "\n";
                }

            } 

            return parse_result;
            
        },

        async addFile(e) {

            var file = e.target.files[0];
            
            if(!file.type.match('text/csv') && !file.type.match('application/csv.*') && !file.type.match('application/vnd.ms-excel.*') && !file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.*')) {
                    this.alertClass.openAlertWarning(this.trans('sh-not-supported-format',221)).then(res => {});
                    this.cData.import.items = [];
                return;
            }

            this.file_uploading = true;
            let form_data = new FormData();
            form_data.append("file", file);
            form_data.append("data", JSON.stringify(this.cData));
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            
            axios
                .post("planning/shifts/addFile", form_data, config)
                .then((res) => {
                    
                    if (res.data.file_data != null && res.data.file_data.length > 0) {
                        this.cData.import.items = res.data.file_data;
                    } else {
                        this.alertClass.openAlertWarning(this.trans('sh-shifts-not-found',221)).then(res => {});
                        this.cData.import.items = [];
                    }
                    
                    if(this.cImportProcedure == '1') {
                        this.cData.columns.items = res.data.columns_data;
                        this.cData.columns.headers = false;
                        this.cData.columns.refresh++;
                    }                    
                })
                .catch((error) => {
                    this.alertClass.openAlertWarning(this.trans('sh-shifts-not-found',221)).then(res => {});
                    
                })
                .finally(() => {
                    this.file_uploading = false; 
                })
        },
        
        removeImportData(){
            this.cData.import.items = [];
            this.cData.validation_configuration = false;
        },

        
    }
   
};
</script>
<style scoped>

.image-upload-wrap {
    border: 1px dashed #BBE7FA;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}


.image-dropping,
.image-upload-wrap:hover {
    background-color: #f8fcfe;
}

.file-upload-input {
    position: absolute;
    left:0;
    top:0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.app-local-cursor-auto {
    cursor: auto;
}

.drag-text {
    text-align: center;
    padding: 20px 10px;
}

.drag-text p {
    color: #000000;
    font-size: 12px;
}

.app-local-row-font {
    color: #174066;
    font-weight: 300;
    font-size: 14px;
}

.app-local-row-header-font {
    font-weight: 600;
    color: #1f627f;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-bottom-line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

</style>
